import React, { Component } from 'react';
import toast, { Toaster } from 'react-hot-toast';

class FormularioInicio extends Component {
    constructor(props) {
        super(props);
        this.state = {
            nombre: '',
            email: '',
            telefono: '',
            ciudadProyecto: '',
            servicio: '',
            tipoProyecto: '',
            mtsCuadrados: '',
            fechaInicio: '',
            metodoContacto: '',
            descripcion: ''
        }
    }

    enviarFormulario = async (e) => {
        e.preventDefault();
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ operacion: 'enviarCorreo', data: this.state })
            };
            const response = await fetch('http://localhost/paginagarteel/src/php/formularioInicio.php', requestOptions);
            const data = await response.json();
            if (data === 'Error') {
                toast.error(
                    'Error al mandar formulario, contacte por otro medio.', {
                        icon: <i style={{ fontSize: '26px', color: '#333' }} className="bi bi-exclamation-circle-fill"></i>,
                        style: {
                            borderRadius: '30px',
                            background: '#FF6F6F',
                            color: '#333',
                            fontSize: '16px',
                            textAlign: 'center',
                            paddingBlock: '5px'
                        }
                    }
                );
            }else{
                this.setState({
                    nombre: '',
                    email: '',
                    telefono: '',
                    ciudadProyecto: '',
                    servicio: '',
                    tipoProyecto: '',
                    mtsCuadrados: '',
                    fechaInicio: '',
                    metodoContacto: '',
                    descripcion: ''
                });                
                toast.success(
                    '¡Listo! Te contactaremos lo antes posbile.', {
                        icon: <i style={{ fontSize: '26px', color: '#333' }} className="bi bi-check-circle-fill"></i>,
                        style: {
                            borderRadius: '30px',
                            background: '#2ebf70',
                            color: '#333',
                            fontSize: '16px',
                            textAlign: 'center',
                            paddingBlock: '5px'
                        }
                    }
                );
            }
    }

    onChangeValue = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }
 
    render() { 
        return (             
            <div className="formulario">
                <form action="" onSubmit={this.enviarFormulario}>
                    <div className="row px-4 pt-3 pb-1" style={{ borderRadius: '10px', backgroundColor: '#333333' }}>
                        <div className="col-12 text-white reveal animate__animated">
                            <p className='m-0 mb-2 titulo'>¿Empezamos?</p>
                        </div>
                        <div className="col-lg-6 reveal animate__animated">
                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="form-floating">
                                        <input type="text" name='nombre' className='form-control inputForm text-white mb-3' value={this.state.nombre} onChange={this.onChangeValue} required placeholder='Nombre Completo'/>
                                        <label className='text-white'>Nombre</label>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="form-floating">
                                        <input type="email" name='email' className='form-control inputForm text-white mb-3' value={this.state.email} onChange={this.onChangeValue} required placeholder='Email'/>
                                        <label className='text-white'>Email</label>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="form-floating">
                                        <input type="tel" name='telefono' className='form-control inputForm text-white mb-3' value={this.state.telefono} onChange={this.onChangeValue} required placeholder='Telefono'/>
                                        <label className='text-white'>Teléfono</label>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="form-floating">
                                        <input type="text" name='ciudadProyecto' className='form-control inputForm text-white mb-3' value={this.state.ciudadProyecto} onChange={this.onChangeValue} required placeholder='Ciudad'/>
                                        <label className='text-white'>Ciudad del proyecto</label>
                                    </div>
                                </div>
                            </div>
                            <label className='text-white mb-1'>Servicio</label>
                                <div className="radioOptions">
                                    <label className='text-white radio-inline' htmlFor="diseno">
                                        <input type="radio" id='diseno' name='servicio' value={'Diseño'} onChange={this.onChangeValue} required/>Diseño
                                    </label>
                                    <label className='text-white radio-inline' htmlFor="construccion">
                                        <input type="radio" id='construccion' name='servicio' value={'Construcción'} onChange={this.onChangeValue} required/>Construcción
                                    </label>
                                    <label className='text-white radio-inline' htmlFor="disenoConstruccion">
                                        <input type="radio" id='disenoConstruccion' name='servicio' value={'Diseño y construcción'} onChange={this.onChangeValue} required/>Diseño y construcción
                                    </label>
                                    <label className='text-white radio-inline' htmlFor="remodelacion">
                                        <input type="radio" id='remodelacion' name='servicio' value={'Remodelación'} onChange={this.onChangeValue} required/>Remodelación
                                    </label>
                                </div>
                            <label className='text-white mb-1'>Tipo de proyecto</label>
                                <div className="radioOptions">
                                    <label className='text-white radio-inline' htmlFor="casa">
                                        <input type="radio" id='casa' name='tipoProyecto' value={'Casa'} onChange={this.onChangeValue} required/>Casa
                                    </label>
                                    <label className='text-white radio-inline' htmlFor="departamento">
                                        <input type="radio" id='departamento' name='tipoProyecto' value={'Departamento'} onChange={this.onChangeValue} required/>Departamento
                                    </label>
                                    <label className='text-white radio-inline' htmlFor="comercial">
                                        <input type="radio" id='comercial' name='tipoProyecto' value={'Comercial'} onChange={this.onChangeValue} required/>Comercial
                                    </label>
                                </div>
                            <label className='text-white mb-1'>Metros cuadrados a construir/construidos</label>
                                <div className="radioOptions">
                                    <label className='text-white radio-inline' htmlFor="100-200">
                                        <input type="radio" id='100-200' name='mtsCuadrados' value={'100-200 m2'} onChange={this.onChangeValue} required/>100 - 200
                                    </label>
                                    <label className='text-white radio-inline' htmlFor="200-300">
                                        <input type="radio" id='200-300' name='mtsCuadrados' value={'200-300 m2'} onChange={this.onChangeValue} required/>200 - 300
                                    </label>
                                    <label className='text-white radio-inline' htmlFor="300-500">
                                        <input type="radio" id='300-500' name='mtsCuadrados' value={'300-500 m2'} onChange={this.onChangeValue} required/>300 - 500
                                    </label>
                                    <label className='text-white radio-inline' htmlFor="500-1000">
                                        <input type="radio" id='500-1000' name='mtsCuadrados' value={'500-1000 m2'} onChange={this.onChangeValue} required/>500 - 1,000
                                    </label>
                                    <label className='text-white radio-inline' htmlFor="1000Mas">
                                        <input type="radio" id='1000Mas' name='mtsCuadrados' value={'Más de 1000 m2'} onChange={this.onChangeValue} required/>1,000 +
                                    </label>
                                </div>
                        </div>
                        <div className="col-lg-6 reveal animate__animated">
                            <label className='text-white mb-1'>Fecha de inicio</label>
                                <div className="radioOptions">
                                    <label className='text-white radio-inline' htmlFor="asap">
                                        <input type="radio" id='asap' name='fechaInicio' value={'Lo antes posible'} onChange={this.onChangeValue} required/>Lo antes posible
                                    </label>
                                    <label className='text-white radio-inline' htmlFor="1-3Meses">
                                        <input type="radio" id='1-3Meses' name='fechaInicio' value={'De 1 a 3 Meses'} onChange={this.onChangeValue} required/>1 - 3 Meses
                                    </label>
                                    <label className='text-white radio-inline' htmlFor="3-6Meses">
                                        <input type="radio" id='3-6Meses' name='fechaInicio' value={'De 3 a 6 Meses'} onChange={this.onChangeValue} required/>3 - 6 Meses
                                    </label>
                                    <label className='text-white radio-inline' htmlFor="6MasMeses">
                                        <input type="radio" id='6MasMeses' name='fechaInicio' value={'Más de 6 Meses'} onChange={this.onChangeValue} required/>Más de 6 meses
                                    </label>
                                </div>
                            <label className='text-white mb-1'>Método de contacto</label>
                                <div className="radioOptions">
                                    <label className='text-white radio-inline' htmlFor="wp">
                                        <input type="radio" id='wp' name='metodoContacto' value={'Whatsapp'} onChange={this.onChangeValue} required/>Whatsapp
                                    </label>
                                    <label className='text-white radio-inline' htmlFor="llamada">
                                        <input type="radio" id='llamada' name='metodoContacto' value={'Llamada telefónica'} onChange={this.onChangeValue} required/>Llamada telefónica
                                        </label>
                                    <label className='text-white radio-inline' htmlFor="email">
                                        <input type="radio" id='email' name='metodoContacto' value={'Correo electrónico'} onChange={this.onChangeValue} required/>Correo electrónico
                                    </label>
                                </div>
                            <label className='text-white mb-1'>Descripción del proyecto</label>
                                <textarea className='form-control' style={{ marginBottom: '20px' }} name="descripcion" value={this.state.descripcion} onChange={this.onChangeValue} cols="30" rows="4"></textarea>
                            <button className='d-flex mx-auto btn mt-4 mb-4 mb-lg-0 btnEnviar'>Enviar</button>
                        </div>
                    </div>
                </form>
                <Toaster position="top-right"/>
            </div>
         );
    }
}
 
export default FormularioInicio;