import React from 'react';
import { Switch, Route} from 'react-router-dom';
import Inicio from './Inicio';
import FormularioConstruir from './Construir/Formulario/Formulario';
import Comprar from './Comprar/Comprar';
import Propiedad from './Comprar/Propiedad';
import Contruir from './Construir/Construir';
import Vender from './Vender/Vender';
import FormularioVender from './Vender/Fornulario/Formulario';
import Contacto from './Contacto/Contacto';
import Invertir from './Invertir/Invertir';
import Proyecto from './Invertir/Proyecto';
import NotFoundRoute from './NotFoundRoute';
import Portafolio from './Portafolio/Portafolio';

class Routes extends React.Component {
  
  constructor(){
    super();
    this.state = {
        links: [],
        linksProyectos: [],
        rutasCreadas: false
    }
}

  componentDidMount = async () => {
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ operacion: 'selectLinks' })
      };
      const response = await fetch('http://localhost/paginagarteel/src/php/DB/propiedades.php', requestOptions);
      const data = await response.json();
      const responseProy = await fetch('http://localhost/paginagarteel/src/php/DB/proyectos.php', requestOptions);
      const dataProy = await responseProy.json();
      this.setState({
        links: data,
        linksProyectos: dataProy
      })
      this.rutasCreadas();

  }

  rutasCreadas = () => {
    this.setState({
      rutasCreadas: true
    })
  }

  render() { 
    return (
      <Switch>
          <Route exact path="/" component={Inicio}/>
          <Route exact path="/portafolio" component={Portafolio}/>
          <Route exact path="/invertir" component={Invertir}/>
          {this.state.linksProyectos[0] && this.state.linksProyectos.map((e, index) => 
            <Route key={index} exact path={'/invertir/' + e.idProyecto + '-' + e.nombreLink} render={(props) => <Proyecto {...props} idProyecto={e.idProyecto}/>}/>
          )}
          <Route exact path="/comprar" component={Comprar}/>
          {this.state.links[0] && this.state.links.map((e, index) => 
            <Route key={index} exact path={'/comprar/' + e.idPropiedad + '-' + e.nombreLink} render={(props) => <Propiedad {...props} idPropiedad={e.idPropiedad}/>}/>
          )}
          <Route exact path="/vender" component={Vender}/>
          <Route exact path="/vender/formulario" component={FormularioVender}/>
          <Route exact path="/construir" component={Contruir}/>
          <Route exact path="/construir/formulario" component={FormularioConstruir}/>
          <Route exact path="/contacto" component={Contacto}/>
          {
            this.state.rutasCreadas && 
              <Route exact path="*" component={NotFoundRoute}/>
          }
      </Switch>
    );
  }
}

export default Routes;