import React, { Component } from 'react';

class CapitalHumano extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }
    
    render() { 
        return (
            <div className="capitalHumano p-4 p-md-5 reveal animate__animated">
                <div className="row d-flex justify-content-center">
                    <div className="col-12">
                        <p className='m-0 text-center' style={{ fontSize: '40px', textTransform: 'uppercase', fontWeight: '200' }}>Capital Humano</p>
                    </div>
                    <div className="col-md-8 col-lg-6 mt-3">
                        <p className='m-0 textoCH'>Contamos con un equipo de profesionales altamente capacitados y comprometidos, que se esfuerzan por brindar el mejor servicio a nuestros clientes. Desde nuestros arquitectos hasta nuestro personal de apoyo, todos trabajan en conjunto para asegurar que nuestros clientes reciban una experiencia excepcional. Nuestro capital humano es la clave de nuestro éxito y estamos orgullosos de tener un equipo tan dedicado.</p>
                    </div>
                </div>
            </div>
         );
    }
}
 
export default CapitalHumano;