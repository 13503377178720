import React from 'react';
import NavBar from '../NavBar';
import WhatsappBtn from '../WhatsappBtn';
import Footer from '../Footer';
import logoContacto from '../../img/contacto.png'

class Contacto extends React.Component {
    
    constructor(){
        super();
        this.state = {
            nombre: '',
            correo: '',
            telefono: '',
            motivo: ''
        }
    }
    
    componentDidMount = () => {
        document.title = 'Contacto - Garteel';
    }

    onChangeValue = (e) => {
        this.setState({
            [e.target.name] : e.target.value
        })
    }

    enviar = async () => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 
              operacion: 'enviarCorreo',
              nombre: this.state.nombre,
              correo: this.state.correo,
              telefono: this.state.telefono,
              motivo: this.state.motivo,
            })
          };
        const response = await fetch('http://localhost/paginagarteel/src/php/contacto.php', requestOptions);
        const data = await response.json();
        console.log(data);
        alert('¡Listo!');
        this.setState({
            nombre: '',
            correo: '',
            telefono: '',
            motivo: ''
        });
    }

    render() { 
        return (
            <>
                <NavBar/>
                <div className="container vender mt-5 pb-5 text-white">
                    <div className="text-center">
                        <h1 style={{ color: '#2EBF70' }}>¿Tienes alguna duda?</h1>
                        <h4>Mándanos un correo y nos pondremos en contacto contigo para ayudarte.</h4>
                    </div>
                    <hr />
                    <div className="row mt-5">
                        <div className="col-lg-9">
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="row">
                                        <label htmlFor='' className='col-lg-2 col-form-label'>Nombre</label>
                                        <div className="col-lg-10">
                                        <input type="text" name="nombre" value={this.state.nombre} className="form-control" onChange={this.onChangeValue}/>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">

                                </div>
                                <div className="col-lg-6">
                                    <div className="row mt-3">
                                        <label htmlFor='' className='col-lg-2 col-form-label'>Correo</label>
                                        <div className="col-lg-10">
                                        <input type="email" name="correo" value={this.state.correo} className="form-control" onChange={this.onChangeValue}/>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="row mt-3">
                                        <label htmlFor='' className='col-lg-2 col-form-label'>Teléfono</label>
                                        <div className="col-lg-10">
                                        <input type="tel" name="telefono" value={this.state.telefono} className="form-control" onChange={this.onChangeValue}/>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="row mt-3">
                                        <label htmlFor='' className='col-lg-1 col-form-label'>Motivo</label>
                                        <div className="col-lg-11">
                                        <textarea name="motivo" className="form-control" value={this.state.motivo} rows="3" onChange={this.onChangeValue}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-3 d-lg-flex justify-content-center align-items-center d-none">
                        <img src={logoContacto} alt="" width={'60%'}/>
                        </div>
                    </div>
                    <div className="row mt-4">
                        <div className="col-12 text-center">
                            <button style={{ width: '200px', backgroundColor: '#2EBF70', border: 'none' }} type="button" className="btn btn-success btnEnviar" onClick={this.enviar}>Enviar</button>
                        </div>
                    </div>
                </div>
                <WhatsappBtn/>
                <Footer/>
            </>
        );
    }
}
 
export default Contacto;