import React from 'react';
import NavBar from '../NavBar';
import WhatsappBtn from '../WhatsappBtn';
import Footer from '../Footer';
import ImageGallery from 'react-image-gallery';
import {Button} from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Modal, Box, Typography, Fade, Backdrop } from '@mui/material';
import '../../css/proyecto.css';
import InputRange from 'react-input-range';
import 'react-input-range/lib/css/index.css';

class Proyecto extends React.Component {
    constructor() {
        super();
        this.state = {
            data: [],
            showModal: false,
            nombre: '',
            numTel: '',
            correo: '',
            formSent: false,
            hideCalculadora: false,
            valueSliderMonto: '',
            sliderMontoMax: '',
            stepSlider: 50000,
            valueSliderTiempo: 1,
            sliderTiempoMax: '',
            inputTasa: 'Garantizada'
        }
        this.refCalculadora = React.createRef()
    }

    cerrarModal = () => {
        this.setState({
            showModal: false,
            formSent: false,
            nombre: '',
            numTel: '',
            correo: '',
        });
    } 

    abrirModal = () => {
        this.setState({
            showModal: true
        })
    }

    componentDidMount = async () => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ operacion: 'selectWhere', idProyecto: this.props.idProyecto })
          };
          const response = await fetch('http://localhost/paginagarteel/src/php/DB/proyectos.php', requestOptions);
          const data = await response.json();
          console.log(data);
          let sliderMontoMax = data[0].dineroARecaudar - data[0].dineroRecaudado;
          let sliderTiempoMax = data[0].plazo*12;
          this.setState({
              data: data[0],
              sliderMontoMax: sliderMontoMax,
              valueSliderMonto: Number(data[0].minInversion),
              sliderTiempoMax: sliderTiempoMax,
              valueSliderTiempo: sliderTiempoMax
          })
          document.title = this.state.data.nombre + ' - Garteel';
    }

    numberWithCommas = (x) => {
        x = Number(x).toFixed(2);
        if (x === 0) {
            return '0.00';
        }else{
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
    }

    diasFaltantes = (fecha) => {
        let fechaActual = new Date();
        let fechaLimite = new Date(fecha + ' 00:00');
        var one_day=1000*60*60*24;
        return Math.ceil((fechaLimite.getTime()-fechaActual.getTime())/(one_day));
    }

    onChangeValue = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    submitModal = async (e) => {
        e.preventDefault()
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ operacion: 'enviarCorreo',
                idProyecto: this.state.data.idProyecto,
                nombreProyecto: this.state.data.nombre,
                ubicacion: this.state.data.ubicacion,
                nombreCliente: this.state.nombre,
                numTelCliente: this.state.numTel,
                correoCliente: this.state.correo
            })
        };
        const response = await fetch('http://localhost/paginagarteel/src/php/proyectoInteres.php', requestOptions);
        const data = await response.json();
        if (data === 'Email Enviado') {
            this.setState({
                formSent: true,
                nombre: '',
                numTel: '',
                correo: '',
            })
        }else{
            alert('Hubo un error, por favor contáctanos por Whatsapp');
        }     
    }

    showCalculadora = () => {
        this.setState({
            hideCalculadora: false
        })
        this.refCalculadora.current.scrollIntoView();
    }

    toggleHideCalculadora = () => {
        this.setState({
            hideCalculadora: !this.state.hideCalculadora 
        })
    }

    theme = createTheme({
        palette: {
          success: {
            main: '#7C9A92',
            contrastText: '#ffffff'
          }
        },
      });
    
      themeBtnCalcular = createTheme({
        palette: {
          success: {
            main: '#2EBF70',
            contrastText: '#ffffff'
          }
        },
      });

    styleModal = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #7C9A92',
        borderRadius: '10px',
        boxShadow: 24,
        p: 3,
      };

    onChangeValueSlider = (value) => {
        if (value < 10000) {
            this.setState({
                valueSliderMonto: value,
                stepSlider: 1000
            })
        }
        if (value >= 10000) {
            this.setState({
                valueSliderMonto: value,
                stepSlider: 5000
            })
        }
        if (value >= 100000) {
            this.setState({
                valueSliderMonto: value,
                stepSlider: 50000
            })
        }
        if (value >= 1000000) {
            this.setState({
                valueSliderMonto: value,
                stepSlider: 100000
            })
        }
    }

    onChangeValueSliderTiempo = (value) => {
        this.setState({
            valueSliderTiempo: value,
        })
    }

    getTextoCalculadora = () => {
        let plazos = 0; 
        let montoPlazo = 0;
        let rendimientos = (this.state.valueSliderMonto * (this.state.inputTasa === 'Garantizada' ? this.state.data.tirGarantizado : this.state.data.tirEsperado))/100;
        let rendimientosGarantizada = (this.state.valueSliderMonto * (this.state.data.tirGarantizado/100));
        let totalRecibido = this.state.valueSliderMonto + rendimientos;
        let pagoFinal = 0;
        if (this.state.data.pagoRendimientos === 'Mensual') {
            let anos = this.state.valueSliderTiempo/12;
            plazos = anos * 12;
            montoPlazo = rendimientosGarantizada/(this.state.data.plazo*12);
            pagoFinal = totalRecibido - (montoPlazo * this.getPlazos(plazos));
        }
        if (this.state.data.pagoRendimientos === 'Trimestral') {
            let anos = this.state.valueSliderTiempo/12;
            plazos = anos * 4;
            montoPlazo = rendimientosGarantizada/(this.state.data.plazo*4);
            pagoFinal = totalRecibido - (montoPlazo * this.getPlazos(plazos));
        }
        if (this.state.data.pagoRendimientos === 'Cuatrimestral') {
            let anos = this.state.valueSliderTiempo/12;
            plazos = anos * 3;
            montoPlazo = rendimientosGarantizada/(this.state.data.plazo*3);
            pagoFinal = totalRecibido - (montoPlazo * this.getPlazos(plazos));
        }
        if (this.state.data.pagoRendimientos === 'Semestral') {
            let anos = this.state.valueSliderTiempo/12;
            plazos = anos * 2;
            montoPlazo = rendimientosGarantizada/(this.state.data.plazo*2);
            pagoFinal = totalRecibido - (montoPlazo * this.getPlazos(plazos));
        }
        if (this.state.data.pagoRendimientos === 'Anual') {
            let anos = this.state.valueSliderTiempo/12;
            plazos = anos * 1;
            montoPlazo = rendimientosGarantizada/(this.state.data.plazo*1);
            pagoFinal = totalRecibido - (montoPlazo * this.getPlazos(plazos));
        }
        return <div><h5 className='d-inline'>Si inviertes </h5><h5 className='d-inline' style={{ color: '#7C9A92', fontSize: '24px', fontWeight: '' }}>${this.numberWithCommas(this.state.valueSliderMonto)}</h5> 
                    <h5 className='d-inline'>, en </h5><h5 className='d-inline' style={{ color: '#7C9A92', fontSize: '24px' }}>{this.state.valueSliderTiempo}</h5><h5 className='d-inline'> meses recibirás </h5>
                    <h5 className='d-inline' style={{ color: '#7C9A92', fontSize: '24px' }}>{this.getPlazos(plazos) + ' '}</h5><h5 className='d-inline'>pagos de </h5><h5 className="d-inline" style={{ color: '#7C9A92', fontSize: '24px', fontWeight: '' }}>${this.numberWithCommas(montoPlazo)}</h5>
                    <h5 className="d-inline"> de manera {this.state.data.pagoRendimientos && (this.state.data.pagoRendimientos).toLowerCase() + ' '} y uno de </h5>
                    <h5 className="d-inline" style={{ color: '#7C9A92', fontSize: '24px', fontWeight: '' }}>${this.numberWithCommas(pagoFinal)}</h5><h5 className="d-inline"> en el mes {this.state.valueSliderTiempo}, correspondiente a tu inversión inicial más tu finiquito.</h5>
                </div>
    }

    getPlazos = (plazos) => {
        if (Number.isInteger(plazos)) {  
            return plazos - 1
        }else{
            return Math.floor(plazos)
        }
    }

    render() { 
        return ( 
            <>
                <NavBar/>

                <div className="container-fluid my-4 proyecto text-white">
                    <div className="row">
                        <div className="col-lg-4 divisor">
                            <div className="row">
                                <div className="col-12">
                                    {this.state.data.imgs &&
                                        <ImageGallery items={this.state.data.imgs} useBrowserFullscreen={false} showPlayButton={false}/>
                                    }
                                </div>
                            </div>
                            <div className='row mt-2'>
                                <div className="col-6">
                                    <ThemeProvider theme={this.theme}>
                                        <Button fullWidth color='success' variant='contained' onClick={this.abrirModal}>me interesa invertir</Button>
                                    </ThemeProvider>
                                </div>
                                <div className="col-6">
                                    <ThemeProvider theme={this.themeBtnCalcular}>
                                        <Button fullWidth color='success' variant='contained' onClick={this.showCalculadora}>Calcular Rendimientos</Button>
                                    </ThemeProvider>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-8">
                            <div className="row">
                                <div className="col-12 mt-5 mt-lg-0 text-center d-flex justify-content-center align-items-center">
                                    <h1 className='p-0 m-0'>{this.state.data.nombre}</h1>
                                </div>
                            </div>
                            <hr style={{color: '#7C9A92', height: '2px'}}/>
                            <div className="row">
                                <div className="col-12 text-center">
                                    <h5>{this.state.data.ubicacion}</h5>
                                    <hr style={{color: '#7C9A92', height: '2px'}}/>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <div className="row mb-0">
                                        <div className="col-6 col-md-3 text-center align-self-center">
                                            <div className="row">
                                                <div className="col-12" style={{ color: '#7C9A92' }}>
                                                    <h3>{'$ ' + this.numberWithCommas(Number(this.state.data.dineroARecaudar))}</h3>
                                                </div>
                                                <div className="col-12">
                                                    <h6>Dinero a Recaudar</h6>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-6 col-md-3  text-center align-self-center divisor">
                                            <div className="row">
                                                <div className="col-12" style={{ color: '#7C9A92' }}>
                                                    <h3>{'$ ' + this.numberWithCommas(Number(this.state.data.dineroRecaudado))}</h3>
                                                </div>
                                                <div className="row">
                                                    <div className="col-12">
                                                        <h6>Dinero Recaudado</h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6  text-center align-self-center divisor">
                                            <div className="row">
                                                <div className="col-6 d-flex justify-content-center align-items-end" style={{ color: '#7C9A92' }}>
                                                    <h3 className='m-0'>{this.state.data.tirGarantizado}%</h3><h6 className='m-0'>Garantizado</h6>
                                                </div>
                                                <div className="col-6 d-flex justify-content-center align-items-end" style={{ color: '#7C9A92' }}>
                                                    <h3 className='m-0'>{this.state.data.tirEsperado}%</h3><h6 className='m-0'>Esperado</h6>
                                                </div>
                                                <div className="row d-flex align-items-center">
                                                    <div className="col-12 d-flex align-items-center justify-content-center">
                                                        <p className='mb-0 pb-0'>Rendimiento del proyecto</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <hr style={{color: '#7C9A92', height: '3px'}} className='mt-1'/>
                                </div>
                            </div>
                            <div className="row text-center"> 
                                <div className="col-6 divisor">
                                    {'Mínimo de inversión: $' + this.numberWithCommas(this.state.data.minInversion)}
                                </div>
                                <div className="col-6">
                                    {'Plazo máximo: ' + this.state.data.plazo + ' año(s)'}
                                </div>
                                <div className="col-12">
                                    <hr style={{color: '#7C9A92', height: '3px'}}/>
                                </div>
                            </div>
                            <div className="row text-center"> 
                                <div className="col-6 divisor">
                                    {'Instrumento: ' + this.state.data.instrumento}
                                </div>
                                <div className="col-6">
                                    {' Respaldo: ' + this.state.data.respaldo}
                                </div>
                                <div className="col-12">
                                    <hr style={{color: '#7C9A92', height: '3px'}}/>
                                </div>
                            </div>
                            <div className="row text-start">
                                <div className="col-12">
                                    {'Pago de rendimientos: ' + this.state.data.pagoRendimientos}
                                </div>
                                <div className="col-12">
                                    <hr style={{color: '#7C9A92', height: '3px'}}/>
                                </div>
                            </div>
                            <div className="row">
                                <div className="row">
                                    <div className="col-12">
                                        <h5>Descripción</h5>
                                    </div>
                                </div>
                                <div className='col-12' dangerouslySetInnerHTML={{ __html: this.state.data.descripcion }}/>
                            </div>
                        </div>
                        <div className="col-12" ref={this.refCalculadora}>
                            <hr style={{color: '#7C9A92', height: '2px'}}/>
                        </div>
                    </div>

                    <div className="row px-2" >
                        <div className="col-10 mb-1">
                            <h5>Calcula tus ganancias</h5>
                        </div>
                        <div className="col-2 mb-1 text-end">
                            <button className='btn btn-outline-success' style={{ maxWidth: '100%', width: 'auto' }} onClick={this.toggleHideCalculadora}>{this.state.hideCalculadora ? <i className="bi bi-caret-up-fill d-flex align-items-center justify-content-center"></i> : <i className="bi bi-caret-down-fill d-flex align-items-center justify-content-center"></i>}</button>
                        </div>
                        <div className="col-lg-6 d-flex align-items-center" hidden={this.state.hideCalculadora}>
                            <div className='w-100'  hidden={this.state.hideCalculadora}>
                                <p>1. Monto a Invertir</p>
                                <div className='d-flex justify-content-center'>
                                    <div className='pt-2 pb-4 sliderCalculadora'>
                                        {
                                            this.state.sliderMontoMax !== '' &&
                                                <InputRange
                                                formatLabel={value => `$${this.numberWithCommas(value)}`}
                                                step={this.state.stepSlider}
                                                maxValue={Number(this.state.sliderMontoMax)}
                                                minValue={Number(this.state.data.minInversion)}
                                                value={this.state.valueSliderMonto}
                                                onChange={value => this.onChangeValueSlider(value)}
                                                />
                                        }
                                    </div>
                                </div>
                                <p>2. Tiempo de finalización de proyecto</p>
                                <div className='d-flex justify-content-center'>
                                    <div className='pt-2 pb-4 sliderCalculadora'>
                                        {
                                            this.state.sliderTiempoMax !== '' &&
                                                <InputRange
                                                formatLabel={value => `${value}Mes(es)`}
                                                step={1}
                                                maxValue={Number(this.state.sliderTiempoMax)}
                                                minValue={1}
                                                value={this.state.valueSliderTiempo}
                                                onChange={value => this.onChangeValueSliderTiempo(value)}/>
                                        }
                                    </div>
                                </div>
                                <p className='text-center' style={{ color: 'gray', fontSize: '12px' }}>* En promedio, nuestros proyectos se venden en 14 meses.</p>
                                <p className='mb-1'>3. Rendimiento</p>
                                <select className='form-control w-auto ms-auto me-auto' name="inputTasa" value={this.state.inputTasa} onChange={this.onChangeValue}>
                                    <option value="Garantizada">Garantizado</option>
                                    <option value="Esperada">Esperado</option>
                                </select>
                                <p className='mt-2 mb-0' style={{ color: 'gray', fontSize: '12px' }}>Independientemente del tiempo en que finalice el proyecto, tus rendimientos se calculan con base a tu inversión.</p>
                                <p className='' style={{ color: 'gray', fontSize: '12px' }}>Trabajaremos para vender el proyecto lo antes posible, pero es importante que consideres que recibirás tu inversión y tus rendimientos en un plazo máximo de {this.state.data.plazo*12} meses. GARANTIZADO.</p>
                            </div>
                        </div>
                        <div className="col-lg-6 mt-4 mt-lg-0 d-flex align-items-center text-dark" hidden={this.state.hideCalculadora}>
                            <div style={{ backgroundColor: 'rgba(255,255,255,0.75)', borderRadius: '5px' }} className='py-3' hidden={this.state.hideCalculadora}>
                                <div className="row">
                                    <div className="col-12 d-flex justify-content-center">
                                        <div style={{ width: '300px' }}>
                                            <div className="row">
                                                <div className="col-6">
                                                    <h5>Inviertes:</h5>
                                                </div>
                                                <div className="col-6 text-end">
                                                    <h5 style={{ color: '#7C9A92', fontSize: '', textDecoration: '' }}>${this.numberWithCommas(this.state.valueSliderMonto)}</h5>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-6">
                                                    <h5>Ganas:</h5>
                                                </div>
                                                <div className="col-6 text-end">
                                                    {this.state.inputTasa === 'Garantizada' ?
                                                        <h5 style={{ color: '#7C9A92', fontSize: '', textDecoration: '' }}>{'$' + this.numberWithCommas((this.state.valueSliderMonto*this.state.data.tirGarantizado)/100)}</h5>
                                                        :
                                                        <h5 style={{ color: '#7C9A92', fontSize: '', textDecoration: '' }}>{'$' + this.numberWithCommas((this.state.valueSliderMonto*this.state.data.tirEsperado)/100)}</h5>
                                                    }
                                                </div>
                                            </div>
                                            <hr />
                                            <div className="row">
                                                <div className="col-6">
                                                    <h5>Total:</h5>
                                                </div>
                                                <div className="col-6 text-end">
                                                    {this.state.inputTasa === 'Garantizada' ?
                                                        <h5 style={{ color: '#7C9A92', fontSize: '', textDecoration: '' }}>{'$' + this.numberWithCommas((this.state.valueSliderMonto + ((this.state.valueSliderMonto*this.state.data.tirGarantizado)/100)))}</h5>
                                                        :
                                                        <h5 style={{ color: '#7C9A92', fontSize: '', textDecoration: '' }}>{'$' + this.numberWithCommas((this.state.valueSliderMonto + ((this.state.valueSliderMonto*this.state.data.tirEsperado)/100)))}</h5>
                                                    }
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-6">
                                                    <h5>Impuestos:</h5>
                                                </div>
                                                <div className="col-6 text-end">
                                                    {this.state.inputTasa === 'Garantizada' ?
                                                        <h5 style={{ color: '#890000', fontSize: '', textDecoration: '' }}>{'$' + this.numberWithCommas(((this.state.valueSliderMonto*this.state.data.tirGarantizado)/100)*0.20)}</h5>
                                                        :
                                                        <h5 style={{ color: '#890000', fontSize: '', textDecoration: '' }}>{'$' + this.numberWithCommas(((this.state.valueSliderMonto*this.state.data.tirEsperado)/100)*0.20)}</h5>
                                                    }
                                                </div>
                                            </div>
                                            <hr />
                                            <div className="row">
                                                <div className="col-6">
                                                    <h5>Recibes:</h5>
                                                </div>
                                                <div className="col-6 text-end">
                                                    {this.state.inputTasa === 'Garantizada' ?
                                                        <h5 style={{ color: '#7C9A92', fontSize: '', textDecoration: '' }}>{'$' + this.numberWithCommas((this.state.valueSliderMonto + ((this.state.valueSliderMonto*this.state.data.tirGarantizado)/100) - (((this.state.valueSliderMonto*this.state.data.tirGarantizado)/100)*0.20)))}</h5>
                                                        :
                                                        <h5 style={{ color: '#7C9A92', fontSize: '', textDecoration: '' }}>{'$' + this.numberWithCommas((this.state.valueSliderMonto + ((this.state.valueSliderMonto*this.state.data.tirEsperado)/100) - (((this.state.valueSliderMonto*this.state.data.tirEsperado)/100)*0.20)))}</h5>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-12 text-center px-5">
                                        {this.getTextoCalculadora()}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12">
                            <hr style={{color: '#7C9A92', height: '2px'}}/>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-3 divisor">
                            <div className='px-3'>
                                <div className="row">
                                    <div className="col-12 px-0">
                                        <h5>Documentos</h5>
                                        <hr style={{color: '#7C9A92', height: '2px'}} className='d-none d-md-block'/>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        {this.state.data.docs && this.state.data.docs.map( (doc, index) => 
                                            <div key={index} className="row">
                                                <div className="col-12">
                                                    <a href={'http://localhost/paginagarteel/src/docs/proyectos/' + doc} target='_blank' rel="noreferrer">{doc}</a>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 divisor">
                            <div className='px-3'>
                                <div className="row">
                                    <div className="col-12 px-0">
                                        <h5>Responsables</h5>
                                        <hr style={{color: '#7C9A92', height: '2px'}} className='d-none d-md-block'/>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        {this.state.data.responsables && this.state.data.responsables.map( (res, index) => 
                                            <div key={index}>
                                                <h6>{res.nombre}</h6>
                                                <h6 className='ms-3' style={{ color: 'gray' }}>- {res.rol}</h6>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className='px-3'>
                                <div className="row">
                                    <div className="col-12 px-0">
                                        <h5>Mapa</h5>
                                        <hr style={{color: '#7C9A92', height: '2px'}} className='d-none d-md-block'/>
                                    </div>
                                </div>
                                <div className='mapaPropiedad' dangerouslySetInnerHTML={{ __html: this.state.data.linkMapa }}/>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={this.state.showModal}
                    onClose={this.cerrarModal}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                    timeout: 500,
                    }}
                >
                    <Fade in={this.state.showModal}>
                        <Box sx={this.styleModal}>
                            {this.state.formSent === false ? 
                                <>
                                    <Typography id="modal-modal-title" variant="h6" component="h2" className='text-center mb-3 pb-2' style={{ borderBottom: '2px solid #7C9A92' }}>
                                        ¡Genial! Apóyanos con unos datos
                                    </Typography>
                                    <form action="" onSubmit={this.submitModal}>
                                        <label>Nombre</label>
                                            <input type="text" required className='form-control mb-1' name='nombre' value={this.state.nombre} onChange={this.onChangeValue}/>
                                        <label>Número Telefónico</label>
                                            <input type="tel" required pattern="[0-9]{10,15}" className='form-control mb-1' name='numTel' value={this.state.numTel} onChange={this.onChangeValue}/>
                                        <label>Correo Electrónico</label>
                                            <input type="email" className='form-control mb-3' name='correo' value={this.state.correo} onChange={this.onChangeValue}/>
                                        <ThemeProvider theme={this.theme}>
                                            <Button fullWidth color='success' variant='contained' type='submit'>Enviar</Button>
                                        </ThemeProvider>
                                    </form>
                                </>
                                :
                                <>
                                    <Typography id="modal-modal-title" variant="h6" component="h2" className='text-center mb-3 pb-2' style={{ borderBottom: '2px solid #7C9A92' }}>
                                        ¡Listo!
                                    </Typography>
                                    <Typography variant='p' component='p' className='text-center d-flex'>
                                        Recibimos tu información y nos pondremos en contacto contigo lo antes posible.
                                    </Typography>
                                </>
                            }
                        </Box>
                    </Fade>
                </Modal>

                <WhatsappBtn/>
                <Footer/>
            </>
         );
    }
}
 
export default Proyecto;