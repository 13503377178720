import React, { Component } from 'react';
import imgPred from '../../img/Inicio/inicio_1.jpg';
import imgDiseno from '../../img/Inicio/inicio_diseno.jpg';
import imgConstruccion from '../../img/Inicio/inicio_construccion.jpg';
import imgRemodelacion from '../../img/Inicio/inicio_remodelacion.jpg';
import imgComercial from '../../img/Inicio/inicio_comercial.jpg';

class PrincipalInicio extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }
    
    render() { 
        return ( 
            <div className="principal">
                <div className="contImg1">
                    <div className='overlay'/>
                    <img src={imgPred} alt="" id='img1' className='img1' width="100" height="100"/>
                    <img src={imgDiseno} alt="" id='imgDiseno' className='img1 imgDiseno' width="100" height="100"/>
                    <img src={imgConstruccion} alt="" id='imgConstruccion' className='img1 imgConstruccion' width="100" height="100"/>
                    <img src={imgRemodelacion} alt="" id='imgRemodelacion' className='img1 imgRemodelacion' width="100" height="100"/>
                    <img src={imgComercial} alt="" id='imgComerciales' className='img1 imgComerciales' width="100" height="100"/>
                </div>
                <div className="contenedoPrincipal h-100 w-100 d-flex justify-content-center align-items-center">
                    <div className='row contenedorServicios w-100 h-100'>
                        <div className="col-md-6 p-0">
                            <div className="elementoServicio" onMouseEnter={() => document.getElementById('imgDiseno').style.opacity = "1"} onMouseLeave={() => document.getElementById('imgDiseno').style.opacity = '0'}>
                                <p className='w-100 h-100 d-flex justify-content-center align-items-center animate__animated animate__fadeInDown animateDelay0_5s'>Diseño</p>
                            </div>
                        </div>
                        <div className="col-md-6 p-0">
                            <div className="elementoServicio" onMouseEnter={() => document.getElementById('imgConstruccion').style.opacity = "1"} onMouseLeave={() => document.getElementById('imgConstruccion').style.opacity = '0'}>
                                <p className='w-100 h-100 d-flex justify-content-center align-items-center animate__animated animate__fadeInDown animate__delay-1s'>Construcción</p>
                            </div>
                        </div>
                        <div className="col-md-6 p-0">
                            <div className="elementoServicio" onMouseEnter={() => document.getElementById('imgRemodelacion').style.opacity = "1"} onMouseLeave={() => document.getElementById('imgRemodelacion').style.opacity = '0'}>
                                <p className='w-100 h-100 d-flex justify-content-center align-items-center animate__animated animate__fadeInDown animateDelay1_5s'>Remodelación</p>
                            </div>
                        </div>
                        <div className="col-md-6 p-0">
                            <div className="elementoServicio" onMouseEnter={() => document.getElementById('imgComerciales').style.opacity = "1"} onMouseLeave={() => document.getElementById('imgComerciales').style.opacity = '0'}>
                                <p className='w-100 h-100 d-flex justify-content-center align-items-center animate__animated animate__fadeInDown animate__delay-2s'>Proyectos Comerciales</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
         );
    }
}
 
export default PrincipalInicio;