import React, { Component } from 'react';
import imgCDMX from '../../img/Inicio/cdmx.png';
import imgEDOMEX from '../../img/Inicio/edomex.png';
import imgHGO from '../../img/Inicio/hidalgo.png';
import imgQRO from '../../img/Inicio/queretaro.png';

class Presencia extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }
    render() { 
        return ( 
            <div className="presencia p-5">
                <div className="row">
                    <div className="col-12 text-center reveal animate__animated">
                        <p className='mb-0' style={{ fontSize: '40px', textTransform: 'uppercase', fontWeight: '200' }}>Tenemos presencia en</p>
                    </div>
                    <div className="col-6 p-0 col-md-3 d-flex align-items-center justify-content-center reveal animate__animated">
                        <div className="row w-100">
                            <div className="col-12 d-flex justify-content-center align-items-center">
                                <img src={imgCDMX} alt="" className='imgEstado floating animateDelay0_5s'/>
                            </div>
                            <div className="col-12">
                                <p className='estado'>Ciudad de México</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-6 p-0 col-md-3 d-flex align-items-center justify-content-center reveal animate__animated">
                        <div className="row w-100">
                            <div className="col-12 d-flex justify-content-center align-items-center">
                                <img src={imgEDOMEX} alt="" className='imgEstado floating animateDelay1s'/>
                            </div>
                            <div className="col-12">
                                <p className='estado'>Estado de México</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-6 p-0 col-md-3 d-flex align-items-center justify-content-center reveal animate__animated">
                        <div className="row w-100">
                            <div className="col-12 d-flex justify-content-center align-items-center">
                                <img src={imgHGO} alt="" className='imgEstado floating animateDelay1_5s'/>
                            </div>
                            <div className="col-12">
                                <p className='estado'>Hidalgo</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-6 p-0 col-md-3 d-flex align-items-center justify-content-center reveal animate__animated">
                        <div className="row w-100">
                            <div className="col-12 d-flex justify-content-center align-items-center">
                                <img src={imgQRO} alt="" className='imgEstado floating animateDelay2s'/>
                            </div>
                            <div className="col-12">
                                <p className='estado'>Querétaro</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
         );
    }
}
 
export default Presencia;