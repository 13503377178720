import React, { Component } from 'react';
import NavBar from '../NavBar';
import Footer from '../Footer';
import WhatsappBtn from '../WhatsappBtn';
import '../../css/portafolio.css';
import { Modal } from 'react-bootstrap';

class Portafolio extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            srcModal: '',
            imagenes: []
        }
    }

    componentDidMount = async () => {
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        };
        const response = await fetch('http://localhost/paginagarteel/src/php/imagenesPortafolio.php', requestOptions);
        let data = await response.json();
        data = Object.values(data);
        this.setState({
            imagenes: data
        })
        var gallery = document.querySelector('#gallery');
        var getVal = function (elem, style) { return parseInt(window.getComputedStyle(elem).getPropertyValue(style)); };
        var getHeight = function (item) { return item.querySelector('.content').getBoundingClientRect().height; };
        var resizeAll = function () {
            var altura = getVal(gallery, 'grid-auto-rows');
            var gap = getVal(gallery, 'grid-row-gap');
            gallery.querySelectorAll('.gallery-item').forEach(function (item) {
                var el = item;
                el.style.gridRowEnd = "span " + Math.ceil((getHeight(item) + gap) / (altura + gap));
            });
        };
        gallery.querySelectorAll('img').forEach(function (item) {
            item.classList.add('byebye');
            if (item.complete) {
                //console.log(item.src);
            }
            else {
                item.addEventListener('load', function () {
                    var altura = getVal(gallery, 'grid-auto-rows');
                    var gap = getVal(gallery, 'grid-row-gap');
                    var gitem = item.parentElement.parentElement;
                    gitem.style.gridRowEnd = "span " + Math.ceil((getHeight(gitem) + gap) / (altura + gap));
                    item.classList.remove('byebye');
                });
            }
        });
        window.addEventListener('resize', resizeAll);
        resizeAll();
    }

    showModal = (src) => {
        this.setState({
            showModal: true,
            srcModal: src
        })
    }

    closeModal = () => {
        this.setState({
            showModal: false,
            srcModal: ''
        })
    }

    render() { 
        return (  
            <>
            <NavBar/>
                <div className="container portafolio my-4">
                    <div className="text-center">
                        <h1 style={{ color: '#2EBF70', textTransform: 'uppercase' }}>Nuestro portafolio</h1>
                        <h4 className='text-white'>Innovación y creatividad en cada proyecto.</h4>
                    </div>
                    <hr className='mt-4'/>
                    <div className="gallery mt-4" id="gallery">
                        {
                            this.state.imagenes.map((e, index) => 
                                <div className="gallery-item" key={index}>
                                    <div className="content">
                                        <img src={'/img/portafolio/' + e} alt="imgPortafolio" onClick={() => this.showModal('/img/portafolio/' + e)}/>
                                    </div>
                                </div>
                            )
                        }
                    </div> 
                    <Modal className='modalPortafolio' fullscreen centered show={this.state.showModal} onHide={this.closeModal}>
                        <Modal.Body className='bodyModal' onClick={() => this.closeModal()}>
                            <img src={this.state.srcModal} alt="" className='imgModal'/>
                        </Modal.Body>
                    </Modal>
                </div>
                <Footer/>
                <WhatsappBtn/>
            </>
        );
    }
}
 
export default Portafolio;