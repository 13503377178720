import React from 'react';
import NavBar from './NavBar';
import Footer from './Footer';
import WhatsappBtn from './WhatsappBtn';
import '../css/inicio.css';
import PrincipalInicio from './Inicio/PrincipalInicio';
import FormularioInicio from './Inicio/FormularioInicio';
import CapitalHumano from './Inicio/CapitalHumano';
import Presencia from './Inicio/Presencia';
import TecnologiaInicio from './Inicio/TecnologiaInicio';
import ConstruccionInicio from './Inicio/ConstrucionInicio';

class Inicio extends React.Component {

    constructor(){
        super();
        this.state = {
            proyecto: [],
            nombre: ''
        }
    }

    componentDidMount = async () => {
        document.title = 'Inicio - Garteel';
        window.addEventListener("scroll", this.reveal);
        this.reveal();
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ operacion: 'selectLinks' })
          };
          const responseProy = await fetch('http://localhost/paginagarteel/src/php/DB/proyectos.php', requestOptions);
          const dataProy = await responseProy.json();
          if (dataProy[0]) {
            this.setState({
                proyecto: dataProy[0]
            })
          }
    }

    reveal = () => {
        var reveals = document.querySelectorAll(".reveal");
        for (var i = 0; i < reveals.length; i++) {
            var windowHeight = window.innerHeight;
            var elementTop = reveals[i].getBoundingClientRect().top;
            var elementVisible = 150;
            if (elementTop < windowHeight - elementVisible) {
                reveals[i].classList.add("animate__fadeIn");
            }
        }
    }

    render() { 
            return <>
                <NavBar/>
                <div className="container-fluid inicio">
                    <PrincipalInicio/>
                    <ConstruccionInicio/>
                    <Presencia/>
                    <CapitalHumano/>
                    <TecnologiaInicio/>
                    <FormularioInicio/>
                </div>
                <Footer/>
                <WhatsappBtn/>
            </>;
    }
}
 
export default Inicio;