import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import selloGarteel from '../../img/selloGarteel.svg';
import '../../css/tarjetapropiedad.css';

class TarjetaPropiedad extends React.Component {
    constructor() {
        super();
        this.state = {
            
        }
    }

    numberWithCommas(x) {
        if (x === 0) {
            return '0.00';
        }else{
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
    }
    
    render() { 
        return ( 
            <>
                <div className="col-sm-6 col-lg-4 col-xxl-3 my-2 tarjetaPropiedad">
                    <Card> 
                        <CardActionArea href={'/comprar/' + this.props.data.idPropiedad + '-' + this.props.data.nombreLink}>
                            {this.props.data.casaGarteel === 'si' && <img src={selloGarteel} alt="" width={'20%'} className='selloGarteel'/>}
                            <CardMedia
                            component="img"
                            width="100"
                            image={"http://localhost/paginagarteel/src/img/propiedades/" + this.props.data.imgP}
                            alt="Propiedad"
                            className='imagenTarjeta'
                            />
                            <CardContent style={{ backgroundColor: '#333333', color: 'white' }}>
                                <Typography gutterBottom variant='h6' component="div" style={{ height: '60px' }}>
                                    {this.props.data.nombre}
                                </Typography>
                                <Typography gutterBottom variant='h6'>
                                    {'$ ' + this.numberWithCommas(this.props.data.precio)}
                                </Typography>
                                <div className="row mt-4">
                                    <div className="col-3">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-bed" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                            <path d="M3 7v11m0 -4h18m0 4v-8a2 2 0 0 0 -2 -2h-8v6"></path>
                                            <circle cx="7" cy="10" r="1"></circle>
                                        </svg>
                                        <p style={{fontWeight: 'bold', fontSize: 'large'}}>{this.props.data.recamaras}</p>
                                    </div>
                                    <div className="col-3">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-bath" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                            <path d="M4 12h16a1 1 0 0 1 1 1v3a4 4 0 0 1 -4 4h-10a4 4 0 0 1 -4 -4v-3a1 1 0 0 1 1 -1z"></path>
                                            <path d="M6 12v-7a2 2 0 0 1 2 -2h3v2.25"></path>
                                            <path d="M4 21l1 -1.5"></path>
                                            <path d="M20 21l-1 -1.5"></path>
                                        </svg>
                                        <p style={{fontWeight: 'bold', fontSize: 'large'}}>{this.props.data.banosCompletos}</p>
                                    </div>
                                    <div className="col-3">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-car" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                            <circle cx="7" cy="17" r="2"></circle>
                                            <circle cx="17" cy="17" r="2"></circle>
                                            <path d="M5 17h-2v-6l2 -5h9l4 5h1a2 2 0 0 1 2 2v4h-2m-4 0h-6m-6 -6h15m-6 0v-5"></path>
                                        </svg>
                                        <p style={{fontWeight: 'bold', fontSize: 'large'}}>{this.props.data.garage}</p>
                                    </div>
                                    <div className="col-3">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-dimensions" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                            <path d="M3 5h11"></path>
                                            <path d="M12 7l2 -2l-2 -2"></path>
                                            <path d="M5 3l-2 2l2 2"></path>
                                            <path d="M19 10v11"></path>
                                            <path d="M17 19l2 2l2 -2"></path>
                                            <path d="M21 12l-2 -2l-2 2"></path>
                                            <rect x="3" y="10" width="11" height="11" rx="2"></rect>
                                        </svg>
                                        <p style={{fontWeight: 'bold', fontSize: 'large'}}>{this.props.data.metrosCuadrados}m<sup>2</sup></p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        {this.props.data.ubicacion}
                                    </div>
                                </div>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </div>
            </>
         );
    }
}
 
export default TarjetaPropiedad;