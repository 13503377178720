import React from 'react';
import NavBar from '../NavBar';
import WhatsappBtn from '../WhatsappBtn';
import Footer from '../Footer';
import TarjetaProyecto from './TartejaProyecto';

class Invertir extends React.Component {
    
    constructor(){
        super();
        this.state = {
            proyectos: [],
        }
    }

    componentDidMount = async () => {
        document.title = 'Invertir - Garteel';
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ operacion: 'select' })
          };
          const response = await fetch('http://localhost/paginagarteel/src/php/DB/proyectos.php', requestOptions);
          const data = await response.json();
          this.setState({
            proyectos: data
        })
    }

    render() { 
        return (
            <>
                <NavBar/>
            
                <div className="container-fluid mb-5 mt-4 pb-4 comprar text-white">
                    <div className="text-center">
                        <h1 style={{ color: '#2EBF70' }}>GRANDES OPORTUNIDADES TE ESPERAN</h1>
                        <h4>Invierte en bienes raíces.</h4>
                    </div>
                    <hr />
                    <div className="row d-flex justify-content-center">
                        {
                            this.state.proyectos.map((e, index) => <TarjetaProyecto key={index} data={e}/>)
                        }
                    </div>
                </div>

                <WhatsappBtn/>
                <Footer/>
            </>
        );
    }
}
 
export default Invertir;